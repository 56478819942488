<template>
	<div class="content">
		<!-- 添加店铺 -->
		<template v-if="authority == 1">
			<a-button class="mb-15" type="primary" @click="add">
			  添加大店铺
			</a-button>
		</template>

		<!-- 表格 -->
		<Table
		:columns="columns" 
		:datasource="datasource" 
		:pagination="pagination"
		@getshow = "getshow"
		@modify = "modify"
		@gettabledata="gettabledata" 
		@bindperson="bindperson"
		>
		</Table>
		
		<!-- 修改 -->
		<Modify ref="modify" :record="record" @modifynav="sumbitmodify" @changeimg = "changeimg"></Modify>
		
		<!-- Add -->
		<Add ref="add" :record="record" @changeimg = "changeimg" @modifynav="sumbitadd"></Add>
		
		<!-- 绑定人员 -->
		<Binding ref="bind" :record="record" @modifynav="submitbind"></Binding>
	</div>
</template>

<script>
	import {mapState,mapGetters,mapMutations,mapActions} from "vuex"
	import Table from "../../components/branchshop/bigshop/table.vue"
	import Modify from "../../components/branchshop/bigshop/modify.vue"
	import Add from "../../components/branchshop/bigshop/add.vue"
	import Binding from "../../components/branchshop/bigshop/binding.vue"
	export default {
		components:{
			Table,
			Modify,
			Add,
			Binding
		},
		data() {
			return {
				authority:0,
				record:{
					data:"",
					classify:""
				},
				pagination:{
					pageSize:4,
					current:1,
					totalPage:0,
					total:0,
					keyword:"",
					platform:""
				},
				columns :[
				  {
				    title: 'ID',
					key:"id",
				    dataIndex: 'id',
					align: 'center' 
				  },
				  {
				    title: '大店铺名称',
				  	key:"name",
				    dataIndex: 'name',
				  	align: 'center' 
				  },
				  {
				    title: '店铺地址',
				  	key:"address",
				    dataIndex: 'address',
				  	align: 'center' 
				  },
				  {
				    title: 'oa系统链接',
				  	key:"oa_url",
				    dataIndex: 'oa_url',
				  	align: 'center' 
				  },
				  {
				    title: '店铺二维码',
				  	key:"wx_qr",
				    dataIndex: 'wx_qr',
				  	align: 'center' ,
					slots: { title: 'wx_qr' }, //插槽列
					scopedSlots: { customRender: "wx_qr" },//插槽列数据
				  },
				  {
				    title: '创建时间',
				  	key:"create_time",
				    dataIndex: 'create_time',
				  	align: 'center' ,
					slots: { title: 'create_time' }, //插槽列
					scopedSlots: { customRender: "create_time" },//插槽列数据
				  },
				  {
				    title: '是否禁止',
				  	key:"status",
				    dataIndex: 'status',
				  	align: 'center' ,
					slots: { title: 'status' }, //插槽列
					scopedSlots: { customRender: "status" },//插槽列数据
				  },
				  {
				    title: '操作',
					key:"action",
				    dataIndex: 'action',
					slots: { title: 'action' }, //插槽列
					scopedSlots: { customRender: "action" },//插槽列数据
					align: 'center' 
				  },
				],
				datasource:[
					{
						id:1,
						name:"家电清洗",
						wx_qr:"服务",
						renyuan:"icon",
						status:false,
					},
				]
			}
		},
		created() {
			// 获取表格数据
			this.gettabledata(this.pagination)
		},
		methods:{
			...mapActions({
				getbigshoplist:"branchshopbigshop/getbigshoplist",
				getstatus:"branchshopbigshop/getstatus",
				getupdate:"branchshopbigshop/getupdate",
				getadd:"branchshopbigshop/getadd",
			}),
			/*
			 *获取数据
			 *pagination 分页参数
			 * 初始化和点击执行
			 */
			async gettabledata(pagination){
				 let data = await this.getbigshoplist(pagination)
				 console.log(data,"大店铺");
				 this.pagination.current = pagination.current
				 this.pagination.total = data.data.total
				 this.pagination.totalPage = data.data.totalPage
				 this.datasource = data.data.data
				 this.authority  = data.authority
				 window.sessionStorage.setItem("authority",this.authority)
			},
			// 显示和禁止
			async getshow(id){
				let msg = await this.getstatus(id)
				this.$message.success(msg)
				this.gettabledata(this.pagination)
			},
			// 打开修改弹窗
			async modify(record){
				console.log("record",record);
				// // 每一行数据record
				this.record.data = record
				this.$refs.modify.show = true
			},
			// 提交修改
			async sumbitmodify(payload){
				payload.banking_icense = JSON.stringify(this.record.data.banking_icense) 
				payload.id_photo = JSON.stringify(this.record.data.id_photo) 
				console.log(payload,"提交修改店铺信息");
				let msg = await this.getupdate(payload)
				this.$message.success(msg)
				this.gettabledata(this.pagination)
				this.$refs.modify.show = false
			},
			// 更换图片
			changeimg(payload){
				console.log(payload,"点击图片");
				if(payload.witchbtn == 1){
					this.record.data.banking_icense.push(payload.val)
				}else if(payload.witchbtn == 2){
					const arr = [...this.record.data.banking_icense]
					arr[payload.index] = payload.val
					this.record.data.banking_icense = arr
					console.log(this.record.data.banking_icense)
				}else if(payload.witchbtn == 3){
					const arr = [...this.record.data.id_photo]
					arr[payload.index] = payload.val
					this.record.data.id_photo = arr
					console.log(this.record.data.id_photo)
				}else{
					this.record.data.id_photo.push(payload.val)
				}
			},
			
			// 打开添加弹窗
			add(){
				// 每一行数据record
				let record = {
					name:"",
					identity_number:"",
					bank_card:"",
					banking_icense:[],
					phone:"",
					store_type:"",
					address:"",
					id_photo:[],
					logo:"",
					theme_color:"",
					service_url:"",
					appid:"",
					app_secret:"",
					oa_url:"",
				}
				this.record.data = record
				this.$refs.add.show = true				
			},
			// 提交添加
			async sumbitadd(payload){
				payload.banking_icense = JSON.stringify(this.record.data.banking_icense)
				payload.id_photo = JSON.stringify(this.record.data.id_photo) 
				console.log(payload,"提交添加店铺信息");
				let msg = await this.getadd(payload)
				this.$message.success(msg)
				this.gettabledata(this.pagination)
				this.$refs.add.show = false
			},
			// 绑定人员
			async bindperson(record){
				console.log(record,"传递参数");
				this.record.data = record
				let pagination={
					pageSize:4,
					current:1,
					totalPage:0,
					total:0,
					keyword:"",
					platform:"",
					shopid:this.record.data.id
				}
				await this.$refs.bind.gettabledata(pagination)
				this.$refs.bind.show = true
			},
			// 提交绑定
			async submitbind(payload){
				console.log(payload,"提交绑定参数");
				// let msg = await this.getbind(payload)
				// this.$message.success(msg)
				// this.gettabledata(this.pagination)
				// this.$refs.bind.show = false
				
			},
		}
	}
</script>

<style>
</style>
