<template>
	<div>
		<a-modal okText="确定" cancelText="取消" v-model="show" title="修改大店铺" @ok="comfirm">
			<template>
				<!-- 表单验证 -->
				<a-form ref="collectionForm" :form="form">
					<!-- id -->
					<a-form-item label="大店铺ID">
						<a-input disabled v-decorator="[
							'id',
							{
							  initialValue: record.data.id,
							  rules: [{ required: true, message: `请填写大店铺ID`, whitespace: true }],
							},
						  ]" />
					</a-form-item>
					<!-- 名称 -->
					<a-form-item label="大店铺名称">
						<a-input disabled v-decorator="[
							'name',
							{
							  initialValue: record.data.name,
							  rules: [{ required: true, message: `请填写大店铺名称`, whitespace: true }],
							},
						  ]" />
					</a-form-item>
					
					<a-form-item label="身份证号码">
						<a-input v-decorator="[
							'identity_number',
							{
							  initialValue: record.data.identity_number,
							  
							},
						  ]" />
					</a-form-item>
					<a-form-item label="结算银行卡号">
						<a-input v-decorator="[
							'bank_card',
							{
							  initialValue: record.data.bank_card,
							  
							},
						  ]" />
					</a-form-item>
					<a-form-item label="点击展示更换营业执照">
						<template v-if="record.data.banking_icense">
							<template v-if="record.data.banking_icense.length>0">
							<div class="f-ac">
								<template v-for="(item,index) in record.data.banking_icense">
									<div style="position: relative;width: 25%;height: 60%;border: 1px solid;margin-right: 15px;">
										<a-icon style="position: absolute;top: 0;right: 0;z-index: 100;" type="delete"  @click="delcarimg(index)"/>
										<img style="width: 100%;height: 100%;cursor: pointer;" :src="item" alt="" @click="openresources(2,index)">
									</div>
								</template>
								<div style="width: 25%;height: 60%;border:1px dashed #CCCCCC;flex-direction: column;padding: 30px;cursor: pointer;" class=" upload f-ajc" @click="openresources(1)">
									<a-icon type="plus" />
									<div class="ant-upload-text">
										添加图片
									</div>
								</div>
							</div>
							</template>
							<template v-else>
								<div style="width: 25%;height: 60%;border:1px dashed #CCCCCC;flex-direction: column;padding: 24px;cursor: pointer;" class="upload f-ajc" @click="openresources(1)">
									<a-icon type="plus" />
									<div class="ant-upload-text">
										上传图片
									</div>
								</div>
							</template>	
						</template>						  
					</a-form-item>
					<a-form-item label="点击展示更换身份证照片">
						<template v-if="record.data.id_photo">
							<template v-if="record.data.id_photo.length>0">
							<div class="f-ac">
								<template v-for="(item,index) in record.data.id_photo">
									<div style="position: relative;width: 25%;height: 60%;border: 1px solid;margin-right: 15px;">
										<a-icon style="position: absolute;top: 0;right: 0;z-index: 100;" type="delete"  @click="delcarimg(index)"/>
										<img style="width: 100%;height: 100%;cursor: pointer;" :src="item" alt="" @click="openresources(3,index)">
									</div>
								</template>
								<div style="width: 25%;height: 60%;border:1px dashed #CCCCCC;flex-direction: column;padding: 30px;cursor: pointer;" class=" upload f-ajc" @click="openresources(4)">
									<a-icon type="plus" />
									<div class="ant-upload-text">
										添加图片
									</div>
								</div>
							</div>
							</template>
							<template v-else>
								<div style="width: 25%;height: 60%;border:1px dashed #CCCCCC;flex-direction: column;padding: 24px;cursor: pointer;" class="upload f-ajc" @click="openresources(4)">
									<a-icon type="plus" />
									<div class="ant-upload-text">
										上传图片
									</div>
								</div>
							</template>	
						</template>						  
					</a-form-item>
					<!-- 图片管理器 -->
					<resources ref="resources"  @getimgpath="getimgpath"></resources>
					
					
					<a-form-item label="手机号">
						<a-input v-decorator="[
							'phone',
							{
							  initialValue: record.data.phone,
							  
							},
						  ]" />
					</a-form-item>
					<a-form-item label="店铺类型">
						<a-input v-decorator="[
							'store_type',
							{
							  initialValue: record.data.store_type,
							  
							},
						  ]" />
					</a-form-item>
					<a-form-item label="图标">
						<a-input v-decorator="[
							'logo',
							{
							  initialValue: record.data.logo,
							  
							},
						  ]" />
					</a-form-item>
					<a-form-item label="主题色">
						<a-input v-decorator="[
							'theme_color',
							{
							  initialValue: record.data.theme_color,
							  
							},
						  ]" />
					</a-form-item>
					<a-form-item label="店铺地址">
						<a-input v-decorator="[
							'address',
							{
							  initialValue: record.data.address,
							  
							},
						  ]" />
					</a-form-item>
					<a-form-item label="客服地址">
						<a-input v-decorator="[
							'service_url',
							{
							  initialValue: record.data.service_url,
							  
							},
						  ]" />
					</a-form-item>
					<a-form-item label="小程序appid">
						<a-input v-decorator="[
							'appid',
							{
							  initialValue: record.data.appid,
							  
							},
						  ]" />
					</a-form-item>
					<a-form-item label="小程序密钥">
						<a-input v-decorator="[
							'app_secret',
							{
							  initialValue: record.data.app_secret,
							  
							},
						  ]" />
					</a-form-item>
					<a-form-item label="oa系统地址">
						<a-input v-decorator="[
							'oa_url',
							{
							  initialValue: record.data.oa_url,
							  
							},
						  ]" />
					</a-form-item>
				</a-form>
			</template>


		</a-modal>
	</div>
</template>

<script>
	import resources from "../../resoureces.vue"
	export default {
		props: {
			record: Object
		},
		components: {
			resources
		},
		data() {
			return {
				show: false,
				isresources: false,
				form: this.$form.createForm(this, {
					name: 'coordinated'
				}),
				witchbtn:1
			}
		},

		created() {

		},
		methods: {
			comfirm(e) {
				console.log(e);
				const form = this.$refs.collectionForm.form;
				form.validateFields((err, values) => {
					if (err) {
						return;
					}
					console.log('Received values of form: ', values);
					
					// 执行提交方法
					this.$emit("modifynav", values)
				});
			},
			// 打开图片管理器
			openresources(btn,index) {
				this.witchbtn  = btn
				this.index = index
				this.$refs.resources.visibles = true
				// this.isresources = true
			},
			// 获取更改图片路径
			getimgpath(val) {
				// 收集数据集
				let payload = {
					val:val,
					witchbtn:this.witchbtn,
					index:this.index
				}
				this.$emit("changeimg", payload)
			},
			// 删除图片
			delcarimg(index){
				this.record.data.banking_icense.splice(index,1)
			},
		}
	}
</script>

<style scoped lang="less">

</style>
