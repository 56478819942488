<template>
	<div>
		<a-modal okText="确定" width="80%" cancelText="取消" v-model="show" title="大店铺账号" @ok="comfirm">
			<template>
				<!-- 表单验证 -->
				<bind-table
				ref="bindtable"
				:columns="columns" 
				:datasource="datasource" 
				:pagination="pagination" 
				:record="record"
				@gettabledata="gettabledata" 
				@getsearch="getsearch">
				</bind-table>
			</template>


		</a-modal>
	</div>
</template>

<script>
	import {mapState,mapGetters,mapMutations,mapActions} from "vuex"
	import bindTable from "./bindtable.vue"

	export default {
		props: {
			record: Object
		},
		components:{
			bindTable
		},
		data() {
			return {
				show: false,
				isresources: false,
				form: this.$form.createForm(this, {
					name: 'coordinated'
				}),
				showbind:false,
				pagination:{
					pageSize:4,
					current:1,
					totalPage:0,
					total:0,
					keyword:"",
					platform:""
				},
				
				columns :[
				  {
				    title: 'ID',
					key:"id",
				    dataIndex: 'id',
					align: 'center' 
				  },
				  {
				    title: '用户账号',
					key:"username",
				    dataIndex: 'username',
					align: 'center' 
				  },
				  {
				    title: '手机号',
				  	key:"phone",
				    dataIndex: 'phone',
					align: 'center' 
				  },
				  {
				    title: '启用状态',
					key:"status",
				    dataIndex: 'status',
					slots: { title: '启用状态' }, //插槽列
					scopedSlots: { customRender: "status" },//插槽列数据
					align: 'center' 
				  },
				  {
				    title: '操作',
					key:"action",
				    dataIndex: 'action',
					slots: { title: '操作' }, //插槽列
					scopedSlots: { customRender: "action" },//插槽列数据
					align: 'center' 
				  },
				],
				datasource:""
			}
		},

		created() {
			
		},
		methods: {
			...mapActions({
				getaccountlist:"branchshopbigshop/getaccountlist",
			}),
			/*
			 *获取数据
			 *pagination 分页参数
			 * 初始化和点击执行
			 */
			async gettabledata(pagination){
				 let data = await this.getaccountlist(pagination)
				 console.log(data,"账号列表");
				 // this.pagination.current = pagination.current
				 // this.pagination.total = data.total
				 // this.pagination.totalPage = data.totalPage
				 this.datasource = data
			
			},
			// 打开用户列表
			async openbind(){
				console.log("111");
				// 获取表格数据
				await this.gettabledata(this.pagination)
				this.showbind = true
			},
			// 搜索用户
			getsearch(val){
				this.pagination.keyword = val
				this.pagination.current = 0
				this.gettabledata(this.pagination);
			},
			// 确定用户
			comfirmbind(e){
				console.log(e,this.$refs.bindtable.user_id,"确定用户");
				this.$nextTick(() => {
				    this.form.setFieldsValue({'user_id':this.$refs.bindtable.user_id})
				});
				
				this.showbind = false
			},
			comfirm(e) {
				console.log(e);
				const form = this.$refs.collectionForm.form;
				form.validateFields((err, values) => {
					if (err) {
						return;
					}
					console.log('Received values of form: ', values);
					// 执行提交方法
					this.$emit("modifynav", values)
				});
			},
		}
	}
</script>

<style scoped lang="less">

</style>
