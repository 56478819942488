<template>
	<div>
		<a-card class="acard f-ac">
			<div class="f-ac">
				<template v-if="authority == 1">
					<div class="f-ac ml-30">
						<a-button  type="primary" @click="add(record)">
							{{"创建账号"}}
						</a-button>
					</div>
				</template>
			</div>
		</a-card>
		<!-- 
	  bordered :边框线
	  columns：表头
	  slots: { title: '是否显示' }, //插槽列
	  scopedSlots: { customRender: '是否显示' },//插槽列数据
	  @change="handleTableChange" //分页、排序、筛选变化时触发
	  -->
		<a-table 
		bordered 
		:columns="columns" 
		:data-source="datasource" 
		:pagination="pagination" 
		
		row-key="id"
		@change="handleTableChange"
		>
			<!-- 自定义表格列显示内容 -->
			<!-- 头像 -->
			<template v-if="authority == 1">
				<template  slot="status" slot-scope="text,record">
					<template v-if="record.status == '1'">
						<a-popconfirm
							  okText="确定"
							  cancelText="取消"
							  v-if="datasource.length"
							  title="确认禁止?"
							  @confirm="() => getshow(record.id)"
							>
							  <a-button type="primary">显示</a-button>
						</a-popconfirm>
					</template>
					<template v-else>
						<a-popconfirm
							  okText="确定"
							  cancelText="取消"
							  v-if="datasource.length"
							  title="确认显示?"
							  @confirm="() => getshow(record.id)"
							>
							  <a-button type="danger">禁止</a-button>
						</a-popconfirm>
					</template>
				</template>
				<template  slot="action" slot-scope="text,record">
					<a-button type="primary" @click="modify(record)">修改</a-button>
					
					<a-popconfirm class="ml-30"
						  okText="确定"
						  cancelText="取消"
						  v-if="datasource.length"
						  title="确认删除?"
						  @confirm="() => delaccount(record.id)"
						>
						<a-button type="danger">删除</a-button>
					</a-popconfirm>
					<!-- 修改账号 -->
					
					<modify-account ref="modifyaccount"  :record="record" @modifyaccount="modifyaccount"></modify-account>
				</template>
			</template>

		</a-table>
		<a-modal okText="确定"  cancelText="取消" v-model="show" title="创建账号" @ok="okaccount(record)">
			<a-form ref="collectionForm" :form="form">
				<a-form-item label="大店铺ID">
					<a-input disabled v-decorator="[
						'shopid',
						{
						  initialValue: record.data.id,
						  rules: [{ required: true, message: `请填写大店铺ID`, whitespace: true }],
						},
					  ]" />
				</a-form-item>
				<!-- 分店名称 -->
				<a-form-item label="账号名称">
					<a-input v-decorator="[
						'username',
						{
						  initialValue: '',
						  rules: [{ required: true, message: `请填写账号名称`, whitespace: true }],
						},
					  ]" />
				</a-form-item>
				
				<a-form-item label="账号密码">
					<a-input v-decorator="[
						'password',
						{
						  initialValue: '',
						  rules: [{ required: true, message: `请填写账号密码`, whitespace: true }],
						},
					  ]" />
				</a-form-item>
				
				<a-form-item label="手机号">
					<a-input v-decorator="[
						'phone',
						{
						  initialValue: '',
						  rules: [{ required: true, message: `请填写手机号`, whitespace: true }],
						},
					  ]" />
				</a-form-item>
																																			
			</a-form>
		</a-modal>
		
		
		
		
	</div>
</template>
<script>
	import {mapState,mapGetters,mapMutations,mapActions} from "vuex"
	import modifyAccount from "./modifyaccount.vue"
	export default {
		props: {
			columns: Array,
			datasource: Array,
			pagination: Object,
			record: Object
		},
		components:{
			modifyAccount
		},
		data() {
			return {
				selectedRowKeysArray:[],
				selectedRowsArray:[],
				user_id:"",
				show:false,
				authority:0,
				form: this.$form.createForm(this, {
					name: 'coordinated'
				}),
			};
		},
		created() {
			this.authority = window.sessionStorage.getItem("authority")
		},
		mounted() {

		},
		methods: {
			...mapActions({
				getcreateaccount:"branchshopbigshop/getcreateaccount",
				getaccountstatus:"branchshopbigshop/getaccountstatus",
				getupdateaccount:"branchshopbigshop/getupdateaccount",
				getdeleteaccount:"branchshopbigshop/getdeleteaccount",
			}),
			// 分页点击触发
			handleTableChange(pagination, filters, sorter) {
			  console.log(pagination);
			 this.$emit("gettabledata",pagination)
			},
			// 搜索关键次
			onSearch(value) {
				console.log(value);
				this.$emit("getsearch",value)
			},
			// 选择用户
			onSelectChange(selectedRowKeys, selectedRows){
				console.log(selectedRows,"选中行");
				this.selectedRowsArray = selectedRows
				this.selectedRowKeysArray = selectedRowKeys;
				 // this.selectedRows = selectedRows;
				this.user_id = selectedRowKeys[0]
				console.log(this.user_id,"用户id");
			},
			// 打开创建账号
			add(record){
				console.log(record,"创建账号");
				this.show = true
			},
			// 提交账号信息
			okaccount(record){
				const form = this.$refs.collectionForm.form;
				form.validateFields(async(err, values) => {
					if (err) {
						return;
					}
					console.log('Received values of form: ', values);
					// 提交账号信息
					let msg = await this.getcreateaccount(values)
					this.$message.success(msg)
					this.pagination.shopid = record.data.id
					this.$emit("gettabledata",this.pagination)
					this.show = false
				});
			},
			// 显示账号状态
			async getshow(id){
				let msg = await this.getaccountstatus(id)
				this.pagination.shopid = this.record.data.id
				this.$emit("gettabledata",this.pagination)
				this.$message.success(msg)
				
			},
			// 修改账号
			modify(record){
				console.log(record,"修改账号");
				this.$refs.modifyaccount.shows = true

			},
			// 提交修改信息
			async modifyaccount(payload){
					console.log(payload,"修改账号参数")
					payload.shopid = this.record.data.id
					// 提交账号信息
					let msg = await this.getupdateaccount(payload)
					this.$message.success(msg)
					this.pagination.shopid = this.record.data.id
					this.$emit("gettabledata",this.pagination)
					this.$refs.modifyaccount.shows = false
			},
			// 删除账号
			async delaccount(id){
				let payload = {
					id:id,
					shopid:this.record.data.id
				}
				let msg = await this.getdeleteaccount(payload)
				this.$message.success(msg)
				this.pagination.shopid = this.record.data.id
				this.$emit("gettabledata",this.pagination)
			}
		},
	};
</script>

<style lang="less" >
	.acard {
		background: @bg-color;

		.ainputsearch {
			width: 200px;
		}
	}
	.img-pic{
		width: 50px;
		height: 50px;
	}
	.set {
		flex-direction: column;

		.setitem {
			width: 100%;

			.sort {
				width: 40%;

				.a-input {
					width: 50px;
					padding-left: 10px;
					margin-left: 5px;
				}
			}

			.switch {
				flex: 1;

				.txt {}

				.a-switch {
					margin-left: 5px;
					padding-left: 10px;
				}
			}
		}
	}
</style>
