<template>
	<div>
		<a-modal okText="确定"  cancelText="取消" v-model="shows" title="修改账号" @ok="modifyaccount(record)">
			<a-form ref="collectionForm" :form="form">
				<a-form-item label="账号ID">
					<a-input disabled v-decorator="[
						'id',
						{
						  initialValue: record.id,
						  rules: [{ required: true, message: `请填写账号ID`, whitespace: true }],
						},
					  ]" />
				</a-form-item>
				<!-- 分店名称 -->
				<a-form-item label="账号名称">
					<a-input v-decorator="[
						'username',
						{
						  initialValue: record.username,
						  rules: [{ required: true, message: `请填写账号名称`, whitespace: true }],
						},
					  ]" />
				</a-form-item>
				
				<a-form-item label="账号密码">
					<a-input v-decorator="[
						'password',
						{
						  initialValue: record.password,
						  rules: [{ required: true, message: `请填写账号密码`, whitespace: true }],
						},
					  ]" />
				</a-form-item>
				
				<a-form-item label="手机号">
					<a-input v-decorator="[
						'phone',
						{
						  initialValue: record.phone,
						  rules: [{ required: true, message: `请填写手机号`, whitespace: true }],
						},
					  ]" />
				</a-form-item>
																																			
			</a-form>
		</a-modal>
	</div>
</template>

<script>
	export default {
		props:{
			record:Object
		},
		data() {
			return {
				form: this.$form.createForm(this, {
					name: 'coordinated'
				}),
				shows:false,
			}
		},
		methods:{
			modifyaccount(record){
				const form = this.$refs.collectionForm.form;
				form.validateFields(async(err, values) => {
					if (err) {
						return;
					}
					console.log('Received values of form: ', values);
					this.$emit('modifyaccount',values)
				});
			},
		}
	}
</script>

<style>
</style>
